import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { ThemeService } from 'src/app/common/service/theme/theme.service';
// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';

// services
import { CommonService } from '../../common/service/common.service';
import { ShowErrorService } from '../../common/service/show-error.service';
import { RestrictKeyPressService } from 'src/app/common/service/restrict-key-press.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: []
})
export class ResetPasswordComponent implements OnInit {
  // Variable declarations
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public isSettingOpen: boolean = false;
  fieldTextType: boolean;
  public resetPassword: any = {};
  public type: any;


  constructor(private themeService: ThemeService,
    private localStorageService: LocalStorageService, public restrictKeyPressService: RestrictKeyPressService, private router: Router, private commonService: CommonService, private showErrorService: ShowErrorService
  ) { }

  ngOnInit(): void {
    var isDark = this.localStorageService.getToken('theme') == "Dark" ? true : false;
    this.themeService.switchThemeAfterLogin(isDark);
    if (isDark)
      document.getElementById("resetPassSwitchTheme").setAttribute('checked', '');
    this.type = window.location.href.split("type=")[1]
  }

  onResetPassword(form) {
    let token = window.location.href.split("token=")[1].split('&')
    if (form.valid) {
      this.resetPassword = {
        password: form.value.password,
        token: token[0]
      }
      this.commonService.callApi(this.callAPIConstants.ResetPassword, this.resetPassword, 'post', true, false).then((success) => {
        if (success.status === 1) {
          this.showErrorService.displayToastr('success', success.message);
          this.router.navigate([this.URLConstants.LOGIN]);
        } else {
          this.showErrorService.displayToastr('error', success.message);
        }
      });
    }
  }

  openSettingBox() {
    this.isSettingOpen = !this.isSettingOpen;
  }

  toggleTheme() {
    var theme = this.themeService.switchTheme();
    this.localStorageService.setToken('theme', theme);
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}


