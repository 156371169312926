<aside class="sidebar">
    <div class="sidebar-inner-wrapper">

        <div class="sidebar-heading d-flex align-items-end justify-content-between w-100">
            <a class="navbar-brand" [routerLink]="[URLConstants.DASHBOARD]">
                <div class="brand-logo">
                    <img class="img-fluid" src="assets/images/logo-white.svg" alt="branding logo">
                </div>
            </a>
            <span (click)="closeSidebar()" class="sidebar-close-icon"><em class="bx bx-x"></em></span>
        </div>
        <div class="sidebar-menu">
            <ul class="sidebar-menu-list">
                <li class="menu-list-item has-child-submenu level1">
                    <a (click)="closeSidebar()" href="#" class="menu-link">
                        <em class='bx bx-door-open'></em>
                        <span class="menu-title">Dashboard</span>
                    </a>
                </li>
                <li class="menu-list-item has-child-submenu level1" (click)="openCloseSubMenu('subUserMenu')"
                    [ngClass]="subUserMenu ? 'submenu-open' : null"
                    [hidden]="!role.userAccess.read || !role.pagesAccess.read">
                    <a style="cursor: pointer;" class="menu-link">
                        <em class='bx bxs-user'></em>
                        <span class="menu-title">Platform Users</span>
                        <em class='bx bxs-chevron-right'></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="subUserMenu ? 'show' : null">
                        <li class="menu-list-item" *ngIf="role.userAccess.read">
                            <a routerLink="/users" (click)="closeSidebar()" class="menu-link">
                                <em class='bx bxs-right-arrow-alt'></em>
                                <span class="menu-title">Manage Users</span>
                            </a>
                        </li>
                        <li class="menu-list-item" *ngIf="role.pagesAccess.read">
                            <a (click)="closeSidebar()" [routerLink]="[URLConstants.PagesList]"
                                routerLinkActive="router-link-active" href="javascript:;" class="menu-link">
                                <em class='bx bxs-right-arrow-alt'></em>
                                <span class="menu-title">Manage Pages</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="menu-list-item has-child-submenu level1" (click)="openCloseSubMenu('subAdminUserMenu')"
                    [ngClass]="subAdminUserMenu ? 'submenu-open' : null" [hidden]="!role.adminUserAccess.read">
                    <a style="cursor: pointer;" class="menu-link">
                        <em class='bx bxs-user'></em>
                        <span class="menu-title">Sub Admin Users</span>
                        <em class='bx bxs-chevron-right'></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="subAdminUserMenu ? 'show' : null">
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.MANAGE_SUB_ADMIN]" (click)="closeSidebar()"
                                class="menu-link">
                                <em class='bx bxs-right-arrow-alt'></em>
                                <span class="menu-title">Manage Sub Admins</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="menu-list-item has-child-submenu level1" (click)="openCloseSubMenu('subModerationMenu')"
                    [ngClass]="subModerationMenu ? 'submenu-open' : null"
                    *ngIf="(role.videosAccess?.read) || (role.commentsAccess?.read) || (role.pageAccess?.read)">
                    <a style="cursor: pointer;" class="menu-link">
                        <em class='bx bxs-note'></em>
                        <span class="menu-title">Moderation</span>
                        <em class='bx bxs-chevron-right'></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="subModerationMenu ? 'show' : null">
                        <li class="menu-list-item" [hidden]="!role.videosAccess?.read">
                            <a [routerLink]="[URLConstants.FeedVideos]" (click)="closeSidebar()" class="menu-link">
                                <em class='bx bxs-right-arrow-alt'></em>
                                <span class="menu-title">Feed Videos</span>
                            </a>
                        </li>
                        <li class="menu-list-item" [hidden]="!role.videosAccess?.read">
                            <a [routerLink]="[URLConstants.FeedPages]" (click)="closeSidebar()" class="menu-link">
                                <em class='bx bxs-right-arrow-alt'></em>
                                <span class="menu-title">Feed Pages</span>
                            </a>
                        </li>
                        <li class="menu-list-item" [hidden]="!role.commentsAccess?.read">
                            <a (click)="closeSidebar()" [routerLink]="[URLConstants.FeedComments]"
                                routerLinkActive="router-link-active" href="javascript:;" class="menu-link">
                                <em class='bx bxs-right-arrow-alt'></em>
                                <span class="menu-title">Feed Comments</span>
                            </a>
                        </li>
                        <li class="menu-list-item" [hidden]="!role.disputeAccess?.read">
                            <a (click)="closeSidebar()" [routerLink]="[URLConstants.DisputeRequests]"
                                routerLinkActive="router-link-active" href="javascript:;" class="menu-link">
                                <em class='bx bxs-right-arrow-alt'></em>
                                <span class="menu-title">Dispute Requests</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="menu-list-item has-child-submenu level1" (click)="openCloseSubMenu('subMasterMenu')"
                    [ngClass]="subMasterMenu ? 'submenu-open' : null" [hidden]="!role.masterDataAccess.read">
                    <a style="cursor: pointer;" class="menu-link">
                        <em class='bx bxs-file'></em>
                        <span class="menu-title">Master Data</span>
                        <em class='bx bxs-chevron-right'></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="subMasterMenu ? 'show' : null">
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.INDUSTRY]" (click)="closeSidebar()" class="menu-link">
                                <em class='bx bxs-right-arrow-alt'></em>
                                <span class="menu-title">Industry</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.SUBINDUSTRY]" (click)="closeSidebar()" class="menu-link">
                                <em class='bx bxs-right-arrow-alt'></em>
                                <span class="menu-title">Sub Industry</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.SKILLS]" (click)="closeSidebar()" class="menu-link">
                                <em class='bx bxs-right-arrow-alt'></em>
                                <span class="menu-title">Skills</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.LANGUAGE]" (click)="closeSidebar()" class="menu-link">
                                <em class='bx bxs-right-arrow-alt'></em>
                                <span class="menu-title">Languages</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="menu-list-item has-child-submenu level1" (click)="openCloseSubMenu('packageMenu')"
                    [ngClass]="packageMenu ? 'submenu-open' : null" *ngIf="role.subscriptionsAccess">
                    <a style="cursor: pointer;" class="menu-link" [hidden]="!role.subscriptionsAccess.read">
                        <em class='bx bx-package'></em>
                        <span class="menu-title">Packages</span>
                        <em class='bx bxs-chevron-right'></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="packageMenu ? 'show' : null">
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.PACKAGES]" (click)="closeSidebar()" class="menu-link">
                                <em class='bx bxs-right-arrow-alt'></em>
                                <span class="menu-title">Manage Packages</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="menu-list-item has-child-submenu level1" (click)="openCloseSubMenu('reportMenu')"
                    [ngClass]="reportMenu ? 'submenu-open' : null" *ngIf="role.reportsAccess">
                    <a style="cursor: pointer;" class="menu-link" [hidden]="!role.reportsAccess.read">
                        <em class='bx bxs-report'></em>
                        <span class="menu-title">Reports</span>
                        <em class='bx bxs-chevron-right'></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="reportMenu ? 'show' : null">
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.PAYMENT_TRN]" (click)="closeSidebar()" class="menu-link">
                                <em class='bx bxs-right-arrow-alt'></em>
                                <span class="menu-title">Payment Transactions</span>
                            </a>
                        </li>
                    </ul>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="reportMenu ? 'show' : null">
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.CREDIT_TRN]" (click)="closeSidebar()" class="menu-link">
                                <em class='bx bxs-right-arrow-alt'></em>
                                <span class="menu-title">Credit Transactions</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="menu-list-item has-child-submenu level1" (click)="openCloseSubMenu('roleMenu')"
                    [ngClass]="roleMenu ? 'submenu-open' : null" *ngIf="role.rolesAccess">
                    <a style="cursor: pointer;" class="menu-link" [hidden]="!role.rolesAccess.read">
                        <em class='bx bxs-user'></em>
                        <span class="menu-title">Roles</span>
                        <em class='bx bxs-chevron-right'></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="roleMenu ? 'show' : null">
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.MANAGE_ROLES]" (click)="closeSidebar()" class="menu-link">
                                <em class='bx bxs-right-arrow-alt'></em>
                                <span class="menu-title">Manage Roles</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="menu-list-item has-child-submenu level1" (click)="openCloseSubMenu('subContentMenu')"
                    [ngClass]="subContentMenu ? 'submenu-open' : null" *ngIf="role.cmsPagesAccess">
                    <a style="cursor: pointer;" class="menu-link" [hidden]="!role.cmsPagesAccess.read">
                        <em class='bx bxs-file-doc'></em>
                        <span class="menu-title">Page Content</span>
                        <em class='bx bxs-chevron-right'></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="subContentMenu ? 'show' : null">
                        <li class="menu-list-item">
                            <a (click)="closeSidebar()" [routerLink]="[URLConstants.CONTENT_PAGE]" class=" menu-link">
                                <em class='bx bxs-right-arrow-alt'></em>
                                <span class="menu-title">Manage Page Content</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="menu-list-item has-child-submenu level1" (click)="openCloseSubMenu('subRequestMenu')"
                    [ngClass]="subRequestMenu ? 'submenu-open' : null" *ngIf="role.identityRequestsAccess">
                    <a style="cursor: pointer;" class="menu-link" [hidden]="!(role.identityRequestsAccess.read)">
                        <em class='bx bxs-plus-square'></em>
                        <span class="menu-title">Manage Verification Requests</span>
                        <em class='bx bxs-chevron-right'></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="subRequestMenu ? 'show' : null">
                        <li class="menu-list-item">
                            <a (click)="closeSidebar()" [routerLink]="[URLConstants.InvestorRequest]"
                                class=" menu-link">
                                <em class='bx bxs-right-arrow-alt'></em>
                                <span class="menu-title">Accredition Verification Requests</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a (click)="closeSidebar()" [routerLink]="[URLConstants.IdentityRequest]"
                                class=" menu-link">
                                <em class='bx bxs-right-arrow-alt'></em>
                                <span class="menu-title">Identity Verification Requests</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</aside>