import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { URLConstants } from '../../../common/constants/routerLink-constants';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class SidebarComponent implements OnInit {
  URLConstants = URLConstants;
  public subUserMenu: boolean = false;
  public subContentMenu: boolean = false;
  public subSettingsMenu: boolean = false;
  public subBlogMenu: boolean = false;
  public subBlogMenuList: boolean = false;
  public subMasterMenu: boolean = false;
  public subAdminUserMenu: boolean = false;
  public roleMenu: boolean = false;
  public subModerationMenu: boolean = false;
  public subRequestMenu: boolean = false;
  public packageMenu: boolean = false;
  public reportMenu: boolean = false;
  @ViewChild('sidebarToggle') sidebarToggle: ElementRef;
  role;

  constructor(
    private localStorageService: LocalStorageService,
    private commonService: CommonService, public renderer: Renderer2) {
    if (this.localStorageService.getToken('role_permission')) {
      this.role = this.commonService.decrypt(this.localStorageService.getToken('role_permission'));
    }
  }

  ngOnInit(): void {
  }

  closeSidebar() {
    var sidebar = Array.from(document.getElementsByClassName('sidebar') as HTMLCollectionOf<HTMLElement>);
    sidebar[0].style.display = 'none';
  }

  openCloseSubMenu(subMenu) {
    var blog = document.getElementById('blog');
    if (subMenu == 'subUserMenu') {
      this.subUserMenu = !this.subUserMenu;
      this.subContentMenu = this.subSettingsMenu = this.subBlogMenu = this.subAdminUserMenu = this.subMasterMenu = this.roleMenu = this.subModerationMenu = this.subRequestMenu = this.packageMenu = this.reportMenu = false;
    } else if (subMenu == 'subContentMenu') {
      this.subContentMenu = !this.subContentMenu;
      this.subUserMenu = this.subSettingsMenu = this.subBlogMenu = this.subAdminUserMenu = this.subMasterMenu = this.roleMenu = this.subModerationMenu = this.subRequestMenu = this.packageMenu = this.reportMenu = false;
    } else if (subMenu == 'subSettingsMenu') {
      this.subSettingsMenu = !this.subSettingsMenu;
      this.subContentMenu = this.subUserMenu = this.subBlogMenu = this.subAdminUserMenu = this.subMasterMenu = this.roleMenu = this.subModerationMenu = this.subRequestMenu = this.packageMenu = this.reportMenu = false;
    } else if (subMenu == 'subBlogMenu') {
      this.subBlogMenu = !this.subBlogMenu;
      this.subContentMenu = this.subSettingsMenu = this.subUserMenu = this.subAdminUserMenu = this.subMasterMenu = this.roleMenu = this.subModerationMenu = this.subRequestMenu = this.packageMenu = this.reportMenu = false;
    } else if (subMenu == 'subMasterMenu') {
      this.subMasterMenu = !this.subMasterMenu;
      this.subContentMenu = this.subSettingsMenu = this.subUserMenu = this.subBlogMenu = this.subAdminUserMenu = this.roleMenu = this.subModerationMenu = this.subRequestMenu = this.packageMenu = this.reportMenu = false;
    } else if (subMenu == 'subBlogMenuList') {
      this.subBlogMenuList = !this.subBlogMenuList;
      if (blog.style.display == 'none') {
        blog.style.display = 'block';
      } else {
        blog.style.display = 'none'
      }
      this.subContentMenu = this.subSettingsMenu = this.subUserMenu = this.subAdminUserMenu = this.subMasterMenu = this.roleMenu = this.subModerationMenu = this.subRequestMenu = this.packageMenu = this.reportMenu = false;
    } else if (subMenu == 'subAdminUserMenu') {
      this.subAdminUserMenu = !this.subAdminUserMenu;
      this.subContentMenu = this.subSettingsMenu = this.subBlogMenu = this.subUserMenu = this.subMasterMenu = this.roleMenu = this.subModerationMenu = this.subRequestMenu = this.packageMenu = this.reportMenu = false;
    }
    else if (subMenu == 'roleMenu') {
      this.roleMenu = !this.roleMenu;
      this.subContentMenu = this.subSettingsMenu = this.subBlogMenu = this.subUserMenu = this.subMasterMenu = this.subModerationMenu = this.subAdminUserMenu = this.subRequestMenu = this.packageMenu = this.reportMenu = false;
    } else if (subMenu == 'subModerationMenu') {
      this.subModerationMenu = !this.subModerationMenu
      this.subContentMenu = this.subSettingsMenu = this.subBlogMenu = this.subUserMenu = this.subMasterMenu = this.subAdminUserMenu = this.roleMenu = this.subRequestMenu = this.packageMenu = this.reportMenu = false;
    }
    else if (subMenu == 'subRequestMenu') {
      this.subRequestMenu = !this.subRequestMenu
      this.subContentMenu = this.subSettingsMenu = this.subBlogMenu = this.subUserMenu = this.subMasterMenu = this.subModerationMenu = this.subAdminUserMenu = this.roleMenu = this.packageMenu = this.reportMenu = false;
    } else if (subMenu == 'packageMenu') {
      this.packageMenu = !this.packageMenu;
      this.subContentMenu = this.subSettingsMenu = this.subBlogMenu = this.subUserMenu = this.subMasterMenu = this.subModerationMenu = this.subAdminUserMenu = this.subRequestMenu = this.roleMenu = this.reportMenu = false;
    } else if (subMenu == 'reportMenu') {
      this.reportMenu = !this.reportMenu;
      this.subContentMenu = this.subSettingsMenu = this.subBlogMenu = this.subUserMenu = this.subMasterMenu = this.subModerationMenu = this.subAdminUserMenu = this.subRequestMenu = this.roleMenu = this.packageMenu = false;
    }
  }
  changeStyle() {
  }
}





