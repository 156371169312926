<div class="d-flex align-items-center justify-content-between card-header">
    <div class="text-left d-flex align-items-center ">
        <div class="icon mr-2"><span class='bx bxs-plus-circle'></span></div>
        <h5 class="mb-0">{{id?'Update':'Add New'}}
            {{type=='industryListing'?'Industry':type=='subIndustryListing'?'Sub
            Industry':type=='skillListing'?'Skills' :type=='languageListing' ? 'Language':'Another'}}
        </h5>
    </div>
    <div class="text-right">
        <div class="close"><em style="font-size: 25px;" class="bx bx-x" (click)="bsModalRef.hide()"></em></div>
    </div>
</div>
<div class="content-wrapper">
    <div class="animated fadeIn">
        <form #form="ngForm">
            <div class="card-body">
                <div class="container">
                    <div class="form-group" *ngIf="type =='industryListing'">
                        <label>Enter Industry<sup class="bx bxs-star danger"></sup></label>
                        <div [ngClass]="((submitted || name.touched) && name.invalid)?'has-danger':''">
                            <input autocomplete="off" class="form-control" type="text" id="name"
                                placeholder="Enter Industry" name="name" #name="ngModel" [(ngModel)]="commonObj.name"
                                required />
                            <div class="error-block text-left" *ngIf="((submitted || name.touched) && name.invalid)">
                                Enter industry
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="type == 'subIndustryListing'">
                        <label>Select Industry<sup class="bx bxs-star danger"></sup></label>
                        <div [ngClass]="((submitted || industryId.touched) && industryId.invalid)?'has-danger':''">
                            <ng-select class="ng-select-line" [items]="industryArr" bindLabel="name" bindValue="_id"
                                placeholder="Select Industry" name="industryId" #industryId="ngModel" id="industryId"
                                [(ngModel)]="commonObj.industryId" required>
                            </ng-select>
                            <div class="error-block text-left"
                                *ngIf="((submitted || industryId.touched) && industryId.invalid)">
                                Select industry
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="type =='subIndustryListing'">
                        <label>Enter Sub Industry<sup class="bx bxs-star danger"></sup></label>
                        <div class="" [ngClass]="((submitted || name.touched) && name.invalid)?'has-danger':''">
                            <input autocomplete="off" class="form-control" type="text" placeholder="Enter Sub Industry"
                                name="name" #name="ngModel" [(ngModel)]="commonObj.name" required>
                            <div class="error-block text-left" *ngIf="((submitted || name.touched) && name.invalid)">
                                Enter sub industry
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="type=='skillListing' || type=='languageListing'">
                        <label>{{(type == 'skillListing') ? 'Enter Skill' : 'Enter Language'}}<sup
                                class="bx bxs-star danger"></sup></label>
                        <div class="" [ngClass]="((submitted || name.touched) && name.invalid)?'has-danger':''">
                            <input autocomplete="off" class="form-control" type="text"
                                [placeholder]="(type == 'skillListing') ? 'Enter Skill' : 'Enter Language'" name="name"
                                #name="ngModel" [(ngModel)]="commonObj.name" required>
                            <div class="error-block text-left" *ngIf="((submitted || name.touched) && name.invalid)">
                                {{(type == 'skillListing') ? 'Enter skill' : 'Enter language'}}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-end">
                        <button type="submit" class="btn btn-primary mr-2"
                            (click)="createUpdate(form,commonObj)">Save</button>
                    </div>

                </div>
            </div>
        </form>
    </div>
</div>