<div class="modal-header">
    <h4 class="modal-title pull-left">{{(type == 'portfolio') ? 'Portfolio' : 'Packages'}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body page-details-modal">
    <div class="mb-5">
        <label>{{(type == 'portfolio') ? 'All Portfolio' : 'All Packages'}}</label>
    </div>
    <ng-container *ngFor="let i of portfolioData;last as last">
        <div class="modal-details">
            <h5>{{(type == 'portfolio') ? i.title : i.name}}</h5>
            <h6>{{i.industries}} . {{i.subIndustries}}</h6>
            <p *ngIf="type=='portfolio'"><span>{{i.duration}} . {{i.startDate | date: 'dd MMM y'}} - {{i.endDate | date:
                    'dd MMM y'}}</span></p>
            <p *ngIf="type=='package'"><span>{{i.deliveryTime}} {{i.deliveryIn}} . ${{i.priceRangeFrom | shortNumber}} -
                    ${{i.priceRangeTo | shortNumber}}</span></p>
            <p>{{(type == 'portfolio') ? i.overview : i.description}}</p>
            <div class="row">
                <div class="d-flex flex-wrap mt-2 ml-3" *ngFor="let file of i.attachments">
                    <div class="video-bar xs-icons mr-2">
                        <div class="video-img-wrap">
                            <img *ngIf="file.type == 'video' || file.type == 'image'"
                                [src]="file.thumb ?(uploadImageUrl + file.thumb) :  'https://unicornapi.indianic.dev/public/image_1622208437807_BaCMN.png'"
                                width="45" height="45" alt="">
                            <img *ngIf="file.type == 'pdf'" src="../../../../assets/images/pdf.png" width="45"
                                height="45" alt="">
                            <div class="position-ab" *ngIf="file.type == 'video'">
                                <div class="play-icon" (click)="openVideo(file)"><span class="bx bx-play"></span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr *ngIf="!last">
    </ng-container>
</div>
<div class="footer-section">
    <div class="d-flex align-items-center">
        <span class="record-count mr-1">Showing</span>
        <ng-select class="ng-select-line" (change)="selectItemPerPage(itemsPerPage)" [items]="itemsPerPageList"
            placeholder="Select" [(ngModel)]="itemsPerPage" [clearable]='false' [searchable]='false'
            [dropdownPosition]="'top'">
        </ng-select>
        <span class="record-count ml-2">out of {{total}} Portfolios</span>
    </div>
    <div class="pagination-list">
        <pagination [itemsPerPage]='itemsPerPage' #customPagination [totalItems]="total" [boundaryLinks]="true"
            (pageChanged)="pagination($event)" [maxSize]="5" previousText="Prev" nextText="Next" firstText="&laquo;"
            lastText="&raquo;">
        </pagination>
    </div>
</div>