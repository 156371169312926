<div class="modal-header">
    <h4 class="modal-title pull-left">{{(type == 'team') ? 'Team Members' : (this.type == 'found') ? 'Found Team
        Members' : 'Investors'}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body found-team-members">
    <app-responsive-data-table [tableSetupData]="tableSetupData" [accessPermission]="accessPermission"
        #responsiveDataTable>
    </app-responsive-data-table>
</div>