import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'storageSize'
})
export class StorageSizePipe implements PipeTransform {

    transform(kilobytes: number, args?: any): any {
        if (isNaN(kilobytes)) return null; // will only work value is a kilobytes
        if (kilobytes === null) return null;
        if (kilobytes == 0) return '0 KB';
        const k = 1024;
        const sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(kilobytes) / Math.log(k));
        return parseFloat((kilobytes / Math.pow(k, i)).toFixed(0)) + ' ' + sizes[i];
    }

}
