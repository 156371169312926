import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';


import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

// plugin
import { NgApexchartsModule } from "ng-apexcharts";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonModule } from '@angular/common';
import { ResponsiveDataTableComponent } from './components/responsive-data-table/responsive-data-table.component';
import { CommonModalComponent } from './components/modals/common-modal/common-modal.component';
import { TemplateDetailsComponent } from './components/modals/template-details/template-details.component';
import { BnDatatableModule } from '../common/service/bn-datatable/bn-datatable.module';
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { LineTruncationLibModule, LineTruncationDirective } from 'ngx-line-truncation';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
// service

// Component
import { ShortNumberPipe } from './Pipes/short-number.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortfolioDetailsComponent } from './components/modals/portfolio-details/portfolio-details.component';
import { ViewVideosComponent } from './components/modals/view-videos/view-videos.component'
import { MarkDisputeComponent } from './components/modals/mark-dispute/mark-dispute.component'
import { AccreditationRequestComponent } from './components/modals/accreditation-request/accreditation-request.component'
import { TeamMemberComponent } from './components/modals/team-member/team-member.component';
import { VideoDescriptionComponent } from './components/modals/video-description/video-description.component'
import { ReviewCommentComponent } from './components/modals/review-comment/review-comment.component'
import { StorageSizePipe } from './Pipes/storage-size.pipe';
import { NgCircleProgressModule } from 'ng-circle-progress';
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}


@NgModule({
  imports: [
    AngularEditorModule,
    NgxSkeletonLoaderModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    ImageCropperModule,
    TooltipModule.forRoot(),
    DragDropModule,
    NgSelectModule,
    BsDropdownModule.forRoot(),
    BnDatatableModule,
    DataTablesModule,
    GooglePlaceModule,
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({ timeOut: 1200 }),
    TypeaheadModule.forRoot(),
    TypeaheadModule,
    PopoverModule.forRoot(),
    LineTruncationLibModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NgApexchartsModule,
    NgCircleProgressModule.forRoot()
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    ResponsiveDataTableComponent,
    CommonModalComponent,
    TemplateDetailsComponent,
    PortfolioDetailsComponent,
    ViewVideosComponent,
    MarkDisputeComponent,
    AccreditationRequestComponent,
    TeamMemberComponent,
    VideoDescriptionComponent,
    ReviewCommentComponent,
    ShortNumberPipe,
    StorageSizePipe
  ],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [
    //components
    HeaderComponent,
    SidebarComponent,
    ResponsiveDataTableComponent,
    TemplateDetailsComponent,
    PortfolioDetailsComponent,
    ViewVideosComponent,
    MarkDisputeComponent,
    AccreditationRequestComponent,
    VideoDescriptionComponent,
    ReviewCommentComponent,
    TeamMemberComponent,
    DragDropModule,
    GooglePlaceModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    //modules
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BnDatatableModule,
    LineTruncationDirective,
    ShortNumberPipe,
    StorageSizePipe,

    //plugin
    AngularEditorModule,
    ImageCropperModule,
    BsDatepickerModule,
    TabsModule,
    PaginationModule,
    NgSelectModule,
    TooltipModule,
    NgxSkeletonLoaderModule,
    NgApexchartsModule,
    NgCircleProgressModule
  ],
  entryComponents: [TemplateDetailsComponent, CommonModalComponent, PortfolioDetailsComponent, ViewVideosComponent, MarkDisputeComponent, AccreditationRequestComponent, TeamMemberComponent, VideoDescriptionComponent, ReviewCommentComponent],
})
export class SharedModule { }
