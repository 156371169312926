import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ITableSetupData } from 'src/app/common/interfaces/interfaces';
import { GetPermissionsService } from 'src/app/common/service/get-permissions.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';

@Component({
  selector: 'app-team-member',
  templateUrl: './team-member.component.html',
  styleUrls: []
})
export class TeamMemberComponent implements OnInit {
  public accessPermission: any;
  public identityId;
  public type;
  public identityType;
  constructor(public modalRef: BsModalRef, public getPermissionsService: GetPermissionsService, public localStorageService: LocalStorageService) {
    // START: Role Permission //
    this.accessPermission = this.getPermissionsService.getRolePermissions('page');
    // END: Role Permission //
  }

  ngOnInit(): void {
    this.localStorageService.setToken('identityId', this.identityId)
    this.setDTableInitialData();
  }

  /*********************************************************************************************
 @Purpose     : Datatable config settings
 @Parameter   : NA
 @Return      : Datatable config
 @optional    : In tempData
                 sort: Boolean ( To enable Sort for that Column )
                 isVisible: Boolean ( Show that Column Statically )
                 columnVisibility: Boolean ( Enable Dynamically changing Column Visibility )
                 filter: Boolean ( To enable Filter for that Column )
 /*********************************************************************************************/
  tableSetupData: ITableSetupData = { cols: [], actions: null, type: null, params: {}, conditions: {}, saveColumnKey: null };
  setDTableInitialData() {
    const tempData = [
      { type: 'pageInfo', colName: 'INFO', colFieldname: 'name', isVisible: true, img: true },
      { type: 'text', colName: 'POSITION', colFieldname: 'position', isVisible: true },
      { type: 'text', colName: 'SHORT BIO', colFieldname: 'bio', isVisible: true },
    ];
    const tempData1 = [
      { type: 'pageInfo', colName: 'INFO', colFieldname: 'name', isVisible: true, img: true },
      { type: 'text', colName: 'CAN BID', colFieldname: 'canBid', isVisible: true },
      { type: 'text', colName: 'SHORT BIO', colFieldname: 'bio', isVisible: true },
    ];
    const tempData2 = [
      { type: 'pageInfo', colName: 'INFO', colFieldname: 'companyName', isVisible: true, invImg: true },
      { type: 'text_url', colName: 'WEBSITE', colFieldname: 'companyUrl', isVisible: true },
      { type: 'rounds', colName: 'INVESTMENT DETAILS', colFieldname: 'rounds', isVisible: true },
    ];
    // if (!this.accessPermission.edit) { this.getPermissionsService.hideActionInTable(tempData); }
    this.tableSetupData.cols = ((this.type == 'team' || this.type == 'found') && (this.identityType != 'Service Provider - company')) ? tempData : (this.identityType == 'Service Provider - company') ? tempData1 : tempData2;
    this.tableSetupData.type = (this.type == 'team') ? 'teamMemberList' : (this.type == 'found') ? 'foundTeamMemberList' : 'investorList';
    this.tableSetupData.saveColumnKey = (this.type == 'team') ? 'teamMemberList' : (this.type == 'found') ? 'foundTeamMemberList' : 'investorList';
    // this.tableSetupData.params = { statusParams: 'pageIds' };
    this.tableSetupData.conditions = {
      showTableHeader: true,
      showTableFooter: true,
      showApplyStatus: false,
      showExport: false,
      showDownload: false,
      showColumnHide: false,
      showFilter: false,
      showFilterExport: false,
      showSaveFilter: false,
      search: false,
      modal: false,
    };
  }
  /*************************************************************/

}
