import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// plugins
import swal from 'sweetalert2';
// plugins
import * as CryptoJS from 'crypto-js';


// constants
import { URLConstants } from '../constants/routerLink-constants';

// environment
import { environment } from 'src/environments/environment';

// service
import { LocalStorageService } from './local-storage.service';
import { ShowErrorService } from './show-error.service'

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  authorised: any = false;
  public API_URL = '';
  public swal = swal;

  constructor(public router: Router, public Http: HttpClient, public localStorageService: LocalStorageService, public showerror: ShowErrorService) {
    this.API_URL = environment.apiUrl;
  }

  /*******************************************************************************************
  @PURPOSE      	: Call api.
  @Parameters 	  : {
                      url : <url of api>
                      data : <data object (JSON)>
                      method : String (get, post)
                      isForm (Optional) : Boolean - to call api with form data
                      isPublic (Optional) : Boolean - to call api without auth header
                    }
  @RETURN         : Data obtain for API
  /*****************************************************************************************/
  public showSkeletonLoader: boolean;
  callApi(url, data, method, isPublic?, isForm?, html?): Promise<any> {
    this.showSkeletonLoader = true;
    let headers;
    if (isPublic) {
      headers = new HttpHeaders({ 'content-Type': 'application/json' });
    } else if (html) {
      headers = new HttpHeaders({ 'content-Type': 'text/html', 'Authorization': this.localStorageService.getToken('accessToken') });
    } else {
      headers = new HttpHeaders({ 'content-Type': 'application/json', 'Authorization': this.localStorageService.getToken('accessToken') });
    }
    if (isForm) {
      headers = new HttpHeaders({ Authorization: this.localStorageService.getToken('accessToken') });
    }
    return new Promise((resolve, reject) => {
      if (method === 'post') {
        this.Http.post(this.API_URL + url, data, { headers }).subscribe((value) => {
          this.showSkeletonLoader = false;
          resolve(value);
        }, (error) => {
          this.error(error);
        });
      } else if (method === 'get') {
        this.Http.get(this.API_URL + url, { headers, params: data }).subscribe((value) => {
          this.showSkeletonLoader = false;
          resolve(value);
        }, (error) => {
          this.error(error);
        });
      } else if (method === 'put') {
        this.Http.put(this.API_URL + url, data, { headers }).subscribe((value) => {
          this.showSkeletonLoader = false;
          resolve(value);
        }, (error) => {

        });
      } else if (method === 'patch') {
        this.Http.patch(this.API_URL + url, data, { headers }).subscribe((value) => {
          this.showSkeletonLoader = false;
          resolve(value);
        }, (error) => {

        });
      } else if (method === 'delete') {
        this.Http.delete(this.API_URL + url, { headers }).subscribe((value) => {
          this.showSkeletonLoader = false;
          resolve(value);
        }, (error) => {
          this.error(error);
        });
      }
    });

  }


  /*****************************************************************************************
 @PURPOSE      : To Show session LogOut popup
 @PARAMETERS   : NA
 @RETURN       : NA
 /*****************************************************************************************/
  sessionLogOut() {
    window.localStorage.clear();
    this.showerror.displayToastr('error', 'Session Timeout')
    this.router.navigate([URLConstants.LOGIN]);
  }
  /****************************************************************************************/

  /*****************************************************************************************
  @PURPOSE      : To Show error on status 401, 422 or any other error
  @PARAMETERS   : NA
  @RETURN       : NA
  /*****************************************************************************************/
  error(error) {
    if (error.status === 401 || error.message == 'Invalid token.') {
      this.sessionLogOut();
    } else if (error.status === 422) {
      this.showerror.displayToastr('error', 'Login Failed. Invalid credentials!')
    } else if (error.status === 406) {
      this.showerror.displayToastr('error', error.error.message)
    } else {
      if (error.error.message)
        this.showerror.displayToastr('error', error.error.message)
      else
        this.showerror.displayToastr('error', 'Internal Server Error')
    }
  }
  /*****************************************************************************************/


  /*****************************************************************************************
  @PURPOSE      : Used for encrypt the value
  @PARAMETERS   : data
  @RETURN       : Encrypted Data
  /*****************************************************************************************/
  encrypt(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), 'secret key 123');
  }
  /****************************************************************************************/

  /*****************************************************************************************
  @PURPOSE      : Used for decryption of encrypted code
  @PARAMETERS   : data
  @RETURN       : Decrypted Data
  /*****************************************************************************************/
  decrypt(data) {
    return JSON.parse(CryptoJS.AES.decrypt(data.toString(), 'secret key 123').toString(CryptoJS.enc.Utf8));
  }
  /****************************************************************************************/


}
