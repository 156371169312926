import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { ITableSetupData } from 'src/app/common/interfaces/interfaces';
import { CommonService } from 'src/app/common/service/common.service';
import { GetPermissionsService } from 'src/app/common/service/get-permissions.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { environment } from 'src/environments/environment';
interface VideoPlayer extends HTMLVideoElement {
  requestPictureInPicture(): any;
}
@Component({
  selector: 'app-video-description',
  templateUrl: './video-description.component.html',
  styleUrls: []
})
export class VideoDescriptionComponent implements OnInit {
  imgUrl = environment.uploadImgUrl;
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  data;
  type;
  count;
  like;
  comment;
  public hideButton: boolean = false;
  isIntro = false;
  public identityId;
  public accessPermission;
  public activestatus: boolean = false;
  public videoDetail: any;
  public video: any;
  public apicalled: boolean = false;
  public videoDes: any = {}
  public statusListData: any = [
    { name: 'Active', value: 'Active' },
    { name: 'InActive', value: 'Inactive' }
  ]
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  constructor(public bsModalRef: BsModalRef, public getPermissionsService: GetPermissionsService, public localStorageService: LocalStorageService, public commonservice: CommonService, public showErrorService: ShowErrorService, public modalService: BsModalService) {
  }

  ngOnInit(): void {
    if (this.videoDetail) {
      this.videoDes.status = (this.videoDetail.commentDetail.status) ? 'Active' : 'Inactive'
      this.video = this.videoDetail.commentDetail.videoDetail
    }
    // START: Role Permission //
    if (this.type == 'feedvideo' || this.type == 'feedpage') {
      this.accessPermission = this.getPermissionsService.getRolePermissions('video');
    } else if (this.type == 'feedcomment') {
      this.accessPermission = this.getPermissionsService.getRolePermissions('comment');
    }
    // END: Role Permission //

    if (this.data.status == 'Active') {
      this.activestatus = true;
    } else {
      this.activestatus = false;
    }
    this.localStorageService.setToken('identityId', this.identityId)
    this.setDTableInitialData();
  }
  /*********************************************************
    @PURPOSE: view video in Picture-in-Picture mode
    *********************************************************/
  viewPictureInPicture() {
    const video: VideoPlayer = this.videoPlayer.nativeElement;
    this.bsModalRef.hide();
    video.requestPictureInPicture();
  }
  /*********************************************************/

  /*********************************************************
  @PURPOSE: add class for full screen
  *********************************************************/
  addFullScreen() {
    const video = document.getElementById('videoblock').classList;
    video.add('fullscreen');
    this.exitEvent();
  }

  /*********************************************************
  @PURPOSE: remove class on full screen exit
  *********************************************************/
  exitEvent() {
    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener('webkitfullscreenchange', exitHandler);
    document.addEventListener('mozfullscreenchange', exitHandler);
    document.addEventListener('MSFullscreenChange', exitHandler);

    function exitHandler() {
      if (!document['webkitIsFullScreen'] && !document['mozFullScreen'] && !document['msFullscreenElement']) {
        const video = document.getElementById('videoblock').classList;
        video.remove('fullscreen');
      }
    }
  }


  /*********************************************************************************************
 @Purpose     : Datatable config settings
 @Parameter   : NA
 @Return      : Datatable config
 @optional    : In tempData
                 sort: Boolean ( To enable Sort for that Column )
                 isVisible: Boolean ( Show that Column Statically )
                 columnVisibility: Boolean ( Enable Dynamically changing Column Visibility )
                 filter: Boolean ( To enable Filter for that Column )
 /*********************************************************************************************/
  tableSetupData: ITableSetupData = { cols: [], actions: null, type: null, params: {}, conditions: {}, saveColumnKey: null };
  setDTableInitialData() {
    const tempData = [
      { type: 'id', colName: 'ID', colFieldname: 'id', isVisible: true },
      { type: 'users', colName: 'USERS', colFieldname: 'name', colTest: 'reportedBy', isVisible: true, img: true },
      { type: 'text', colName: 'REASON', colFieldname: 'reason', isVisible: true },
      { type: 'date_time', colName: 'REPORTED ON', colFieldname: 'createdAt', isVisible: true },
    ];
    // if (!this.accessPermission.edit) { this.getPermissionsService.hideActionInTable(tempData); }
    this.tableSetupData.cols = tempData;
    if (this.type == 'feedvideo') {
      this.tableSetupData.type = 'feedVideosList';
      this.tableSetupData.saveColumnKey = 'feedVideosList';
    } else if (this.type == 'feedpage') {
      this.tableSetupData.type = 'feedPagesList';
      this.tableSetupData.saveColumnKey = 'feedPagesList';
    } else if (this.type == 'feedcomment') {
      this.tableSetupData.type = 'feedCommentsList';
      this.tableSetupData.saveColumnKey = 'feedCommentsList';
    }
    // this.tableSetupData.params = { statusParams: 'pageIds' };
    this.tableSetupData.conditions = {
      showTableHeader: true,
      showTableFooter: true,
      showApplyStatus: false,
      showExport: false,
      showDownload: false,
      showColumnHide: false,
      showFilter: false,
      showFilterExport: false,
      showSaveFilter: false,
      search: false,
      modal: false,
    };
  }
  /*************************************************************/
  closeHide(feed) {
    const data = {
      videoIds: [feed._id],
      status: (feed.status == 'Active') ? 'Inactive' : 'Active'
    }
    this.commonservice.callApi(this.callAPIConstants.changeVideoStatus, data, 'post', false, false).then((success) => {
      if (success.status == 1) {
        this.showErrorService.displayToastr('success', success.message)
        if (data.status == 'Active') {
          this.activestatus = true
          this.data.status = data.status
        } else {
          this.activestatus = false;
          this.data.status = data.status
        }
        // this.getFeedVideos();
      }
    })
  }
  statusChange(type) {
    const dataObj = {
      commentIds: [this.identityId],
      status: type,
    };
    this.commonservice.callApi(this.callAPIConstants.commentChangeStatus, dataObj, 'post', false, false).then((success) => {
      if (success.status == 1) {
        this.apicalled = true;
        this.showErrorService.displayToastr('success', success.message)
      }
    })
  }
  changedesc() {
    if (this.type == 'feedcomment' && this.apicalled) {
      this.modalService.onHidden.emit({ done: true });
    }
    this.bsModalRef.hide()
  }
}
