<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body page-details-modal">
    <vg-player *ngIf="type == 'video'">
        <vg-controls [vgAutohide]="true" [vgAutohideTime]="1">
            <vg-play-pause></vg-play-pause>
            <vg-scrub-bar [vgSlider]="true" class="d-flex">
                <vg-scrub-bar-current-time [vgSlider]="true">
                </vg-scrub-bar-current-time>
                <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
            </vg-scrub-bar>
            <div class="custom-play-pause-time d-flex">
                <vg-time-display vgProperty="current" vgFormat="mm:ss">
                </vg-time-display>
                <span
                    style="width: 1px;background-color: #ffffff;height: 13px;margin-top: 18px;transform: rotate(10deg)"></span>
                <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
            </div>
            <div class="custom-controls-icons d-flex align-items-center">
                <vg-mute></vg-mute>
                <span class="icon-maximize" (click)="viewPictureInPicture()"></span>
                <vg-fullscreen (click)="addFullScreen()"></vg-fullscreen>
            </div>
        </vg-controls>
        <vg-overlay-play [vgFor]="'videofeed'"></vg-overlay-play>
        <vg-buffering [vgFor]="'videofeed'"></vg-buffering>
        <video [vgMedia]="videoPlayer" #videoPlayer [id]="'videofeed'" class="video-feed" preload="auto" autoplay
            [src]="(data.videoPath ? data.videoPath : data.path) ? (imgUrl + (data.videoPath ? data.videoPath : data.path)) : '../../../../assets/video/SampleVideo_770x460.mp4'">
        </video>
    </vg-player>
    <p *ngIf="type == 'description'">{{data}}</p>
</div>