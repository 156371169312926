import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { environment } from 'src/environments/environment';
import { ViewVideosComponent } from '../view-videos/view-videos.component';

@Component({
  selector: 'app-portfolio-details',
  templateUrl: './portfolio-details.component.html',
  styleUrls: []
})
export class PortfolioDetailsComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  uploadImageUrl = environment.uploadImgUrl;
  public APIParameters: any = { page: 1, pagesize: 4 };
  public portfolioData: Array<any> = [];
  public itemsPerPageList = [2, 5, 10, 25, 100];
  public itemsPerPage = 4;
  identityId;
  type;
  public total;
  constructor(public modalRef: BsModalRef, public commonservice: CommonService, public localStorageService: LocalStorageService, public modalService: BsModalService) { }

  ngOnInit(): void {
    this.getPortfolioDetails()
  }
  getPortfolioDetails(size?) {
    if (size) {
      this.APIParameters.pagesize = size
      this.itemsPerPage = size
    }
    this.APIParameters.identityId = this.identityId;
    const method = (this.type == 'portfolio') ? this.callAPIConstants.GetPortfolio : this.callAPIConstants.GetPackages
    this.commonservice.callApi(method, this.APIParameters, 'post', false, false).then((success) => {
      if (success.status == 1) {
        this.portfolioData = success.data.listing;
        this.total = success.total;
      }
    })
  }
  pagination(e) {
    this.localStorageService.setToken('currentPage', e.page);
    this.localStorageService.setToken('itemsPerPage', e.itemsPerPage);
    this.APIParameters.page = e.page;
    // this.itemsPerPage = e.itemsPerPage;
    this.getPortfolioDetails()
  }

  selectItemPerPage(e) {
    // this.APIParameters.pagesize = e;
    // this.APIParameters.page = 1;
    this.getPortfolioDetails(e);
  }
  openVideo(data) {
    this.modalService.show(ViewVideosComponent,
      { initialState: { data: data, type: 'video' }, class: 'introduction-wrap modal-dialog-centered' });
  }
}
