import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// service
import { CommonService } from './common.service';
import { LocalStorageService } from './local-storage.service';

// constants
import { URLConstants } from '../constants/routerLink-constants';

@Injectable({
  providedIn: 'root',
})
export class GetPermissionsService {
  URLConstants = URLConstants;
  public defaultPermissionConstants = {
    create: true,
    delete: true,
    edit: true,
    read: true,
  };

  constructor(private localStorageService: LocalStorageService, private commonService: CommonService, private router: Router) { }

  /****************************************************************************
  @PURPOSE      : Get Pemission according to Role
  @PARAMETERS   : key
  @RETURN       : roleKey
  /****************************************************************************/
  getRolePermissions(key) {
    let role;
    if (this.localStorageService.getToken('role_permission')) {
      role = this.commonService.decrypt(this.localStorageService.getToken('role_permission'));
      let roleKey: any;
      switch (key) {
        case 'user':
          {
            roleKey = role.userAccess;
          }
          break;
        case 'page': {
          roleKey = role.pagesAccess;
        }
          break;
        case 'subUser':
          {
            roleKey = role.adminUserAccess;
          }
          break;
        case 'role':
          {
            roleKey = role.rolesAccess;
          }
          break;
        case 'cms':
          {
            roleKey = role.cmsPagesAccess;
          }
          break;
        case 'emailTemplate':
          {
            roleKey = role.emailTemplateAccess;
          }
          break;
        case 'master':
          {
            roleKey = role.masterDataAccess;
          }
          break;
        case 'subscription':
          {
            roleKey = role.subscriptionsAccess;
          }
          break;
        case 'video': {
          roleKey = role.videosAccess
        }
          break;
        case 'request': {
          roleKey = role.identityRequestsAccess
        }
          break;
        case 'comment': {
          roleKey = role.commentsAccess
        }
          break;
        case 'report': {
          roleKey = role.reportsAccess;
        }
        break;
        case 'page': {
          roleKey = role.pageAccess;
        }
        break;
        case 'dispute': {
          roleKey = role.disputeAccess;
        }
        break;
        default:
          {
            roleKey = this.defaultPermissionConstants;
          }
          break;
      }
      return roleKey;
    }
  }
  /****************************************************************************/

  /****************************************************************************
  @PURPOSE      : Hide Action In Responsive Data Table
  @PARAMETERS   : tempData
  @RETURN       : NA
  /****************************************************************************/
  hideActionInTable(tempData) {
    const index = tempData.findIndex((o) => o.type === 'action');
    return tempData[index].isVisible = false;
  }
  /****************************************************************************/

  /****************************************************************************
  @PURPOSE      : Common function for AUTH GUARD
                  (canActivate gives value False and Routes to Dashboard)
  @PARAMETERS   : condition
  @RETURN       : Boolean
  /****************************************************************************/
  CanActivateFalseRouteDashboard(condition) {
    if (condition) {
      return true;
    }
    this.router.navigate([this.URLConstants.DASHBOARD]);
    return false;
  }
  /****************************************************************************/
}
